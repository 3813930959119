<template>
  <v-row>
    <v-col cols="8" class="px-0">
      <v-row no-gutters justify="center" align="center" class="text-center">
        <v-col cols="2" class="px-0 mx-4">
          <v-card
            class="fn24-weight7 pa-2 elevation-2"
            max-height="122"
            max-width="136"
            height="122"
            width="136"
          >
            <div style="color: #4f4f4f">
              <a-icon type="team" style="font-size: 40px" />
            </div>
            <div style="color: #4f4f4f">50,828</div>
            <div class="fn12" style="color: #4f4f4f">Total Reach</div>
          </v-card>
        </v-col>

        <v-col cols="2" class="px-0 ml-8">
          <v-card
            class="fn24-weight7 pa-2 elevation-8"
            max-height="122"
            max-width="136"
            height="122"
            width="136"
            color="#1A347F"
          >
            <div style="color: white">
              <a-icon type="carry-out" style="font-size: 40px" />
            </div>
            <div style="color: white">5</div>
            <div class="fn12" style="color: white">Active campaigns</div>
          </v-card>
        </v-col>

        <v-row justify="center" align="center" class="text-center ma-0 pa-0">
          <v-col cols="3" md="3" sm="12" class="px-0 ma-0">
            <!-- <v-card
              class="fn20-weight7 pa-2 elevation-2"
              style="width: 87px; height: 122px"
            >
              <div>
                <v-img
                  src="@/assets/images/trophy.png"
                  width="36"
                  max-width="36"
                  height="36"
                  max-height="36"
                  class="mx-auto mb-4"
                />
              </div>
              <div>5,904</div>
              <div class="fn12">Platinum</div>
            </v-card> -->
          </v-col>
          <v-col cols="3" md="3" sm="12" class="px-0 ma-0">
            <!-- <v-card
              class="fn20-weight7 pa-2 elevation-2"
              style="width: 87px; height: 122px"
            >
              <div>
                <v-img
                  src="@/assets/images/trophy1.png"
                  width="33"
                  max-width="33"
                  height="33"
                  max-height="33"
                  class="mx-auto mb-5"
                />
              </div>
              <div>10,332</div>
              <div class="fn12">Gold</div>
            </v-card>  --> </v-col
          ><v-col cols="3" md="3" sm="12" class="px-0 ma-0">
            <!-- <v-card
              class="fn20-weight7 pa-2 elevation-2"
              style="width: 87px; height: 122px"
            >
              <div>
                <v-img
                  src="@/assets/images/trophy2.png"
                  width="26"
                  max-width="26"
                  height="26"
                  max-height="26"
                  class="mx-auto mb-7"
                />
              </div>
              <div>13,291</div>
              <div class="fn12">Sliver</div>
            </v-card> -->
          </v-col>
        </v-row>
        <v-col cols="12" class="ml-8">
          <v-card
            height="283"
            width="626"
            max-height="283"
            max-width="626"
            class="py-4"
          >
            <div class="fn12 text-center">Total Activities</div>
            <apexchart
              class="mx-auto"
              type="bar"
              height="230"
              width="610"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </v-card>
        </v-col>
        <v-col cols="12" class="mt-6 ml-8">
          <v-row>
            <v-col cols="5">
              <v-card height="168" max-height="168" width="626" max-width="626">
                <v-row>
                  <v-col cols="8" class="mt-4">
                    <div class="fn12-weight7 my-2" style="margin-left: -150px">
                      <span>Redeem VS Points</span>
                      <a-icon
                        type="caret-down"
                        class="ml-4"
                        style="color: #eb5757"
                      />
                      <span class="fn16-weight7 ml-2" style="color: #eb5757"
                        >49.9%</span
                      >
                    </div>
                  </v-col>
                  <v-col cols="4" class="mt-4">
                    <div class="fn12-weight7 mx-10 my-2">
                      <span style="color: #1a347f">All user types</span>
                      <a-icon type="down" class="ml-4" />
                    </div>
                  </v-col>
                  <v-col cols="10" class="mx-10 mt-n4">
                    <div
                      class="fn14-weight7 mb-2"
                      style="color: #1a347f; margin-left: 190px"
                    >
                      Redeem 2,300
                    </div>
                    <v-progress-linear
                      v-model="power"
                      color="#1A347F"
                      background-opacity="1"
                      background-color="#E4E4E4"
                      height="10"
                      rounded
                      stream
                      buffer-value="100"
                    ></v-progress-linear>
                    <!-- <KProgress
                      percent="76"
                      color="#1A347F"
                      bg-color="#E4E4E4"
                      :format="formatText"
                    /> -->
                    <div class="fn14 mt-2">
                      <span style="opacity: 0.4; margin-left: -10px">0</span>
                      <span style="opacity: 0.4; margin-left: 70px">500</span>
                      <span style="opacity: 0.4; margin-left: 70px">1000</span>
                      <span
                        class="fn14-weight7"
                        style="color: #1a347f; margin-left: 240px"
                        >Total 4,609</span
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="1" class="mt-n3" style="margin-left: 360px">
              <v-card
                height="168"
                max-height="168"
                width="320"
                max-width="320"
                class="py-2 ml-10"
              >
                <div class="fn12 text-left mx-8">User Tiers</div>
                <apexchart
                  class="mx-auto"
                  type="pie"
                  width="320"
                  :options="chartOptionPies"
                  :series="seriePies"
                ></apexchart>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="4" class="px-0 ml-0 mt-3">
      <!-- <v-autocomplete
        v-model="date"
        dense
        outlined
        color="#1A347F"
      ></v-autocomplete> -->
      <v-date-picker
        v-model="date"
        elevation="2"
        color="#1A347F"
        width="320"
        max-width="320"
      ></v-date-picker>
    </v-col>
  </v-row>
</template>
<script>
// import KProgress from "k-progress";
export default {
  components: {
    // KProgress,
  },
  data() {
    return {
      items: ["All user types"],
      power: 70,
      date: new Date().toISOString().substr(0, 10),
      series: [
        {
          name: "Active Campaigns",
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
          color: "#27AE60",
        },
        {
          name: "Friends added",
          data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
          color: "#1A347F",
        },
        {
          name: "Friends Blocked",
          data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
          color: "#EB5757",
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",
          zoom: {
            enabled: false,
          },
          fontFamily: "OpenSans",
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
            columnWidth: "80%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 4,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "04/21",
            "05/21",
            "06/21",
            "07/21",
            "08/21",
            "09/21",
            "10/21",
          ],
        },
      },

      seriePies: [44, 55, 13],
      chartOptionPies: {
        chart: {
          width: 300,
          type: "pie",
          zoom: {
            enabled: false,
          },
          fontFamily: "OpenSans",
        },
        labels: ["VET 20%", "Pharmarcie 35%", "Cuetomers 37%"],
        responsive: [
          {
            breakpoint: 400,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        dataLabels: {
          enabled: false,
        },
        theme: {
          // palette: "palette6",
          // enabled: true,
          monochrome: {
            enabled: true,
            color: "#255aee",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        },
      },
    };
  },
};
</script>
<style scoped>
.theme-light {
  /* background-color: rgb(235, 240, 248); */
}
</style>
